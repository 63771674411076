import React, { Component } from "react";
import culture_image from "./components/app_content/navigation_pages_content/images/drummer.jpg";
import bodyparts from "./components/app_content/navigation_pages_content/images/bodyparts.jpg";
import bodyparts_2 from "./components/app_content/navigation_pages_content/images/bodyparts.jpg";
import pain_image from "./components/app_content/navigation_pages_content/images/pain.png";
import fire_image from "./components/app_content/navigation_pages_content/images/fire.png";
import profession from "./components/app_content/navigation_pages_content/images/pharmacist.jpg";
import homenouns_image from "./components/app_content/navigation_pages_content/images/homenouns.jpg";
import at_home_2_image from "./components/app_content/navigation_pages_content/images/homenouns.jpg";
import homeverbs_image from "./components/app_content/navigation_pages_content/images/homeverbs.jpg";
import rubiks_cities_image from "./components/app_content/navigation_pages_content/images/ghana-cities-rubiks-dgray3.png";
import rubiks_image from "./components/app_content/navigation_pages_content/images/rubiks.png";
import animals_image from "./components/app_content/navigation_pages_content/images/animals.jpg";
import colors_image from "./components/app_content/navigation_pages_content/images/colors.jpg";
import family_image from "./components/app_content/navigation_pages_content/images/family.jpg";
import chores_image from "./components/app_content/navigation_pages_content/images/chores.jpg";
import medicalnouns_image from "./components/app_content/navigation_pages_content/images/medicine_welcome1.png";
import greetings_image from "./components/app_content/navigation_pages_content/images/greetings.jpg";
import testyourtwi_image from "./components/app_content/navigation_pages_content/images/testyourtwi.jpg";
import marketfood2_image from "./components/app_content/navigation_pages_content/images/market.png";
import romance_image from "./components/app_content/navigation_pages_content/images/romance.jpg";
import romance_2_image from "./components/app_content/navigation_pages_content/images/romance.jpg";
import market from "./components/app_content/navigation_pages_content/images/market.png";
import haggling_image from "./components/app_content/navigation_pages_content/images/haggling.png";
import cedi_image from "./components/app_content/navigation_pages_content/images/cedi.png";
import this_image from "./components/app_content/navigation_pages_content/images/this.png";
import bar from "./components/app_content/navigation_pages_content/images/bar.png";
import at_school_2_image from "./components/app_content/navigation_pages_content/images/teacher.png";
import adinkra_image from "./components/app_content/navigation_pages_content/images/adinkra_beredum.png";
import teaching_image from "./components/app_content/navigation_pages_content/images/mathteacher.png";
import farmer_image from "./components/app_content/navigation_pages_content/images/okuani.png";
import student_image from "./components/app_content/navigation_pages_content/images/teacher.png";
import woman_image from "./components/app_content/navigation_pages_content/images/woman.png";
import akan_man_image from "./components/app_content/navigation_pages_content/images/akan-man-gold.png";
import today_image from "./components/app_content/navigation_pages_content/images/today.png";
import yesterday_image from "./components/app_content/navigation_pages_content/images/yesterday.png";
import tomorrow_image from "./components/app_content/navigation_pages_content/images/tomorrow.png";
import come_image from "./components/app_content/navigation_pages_content/images/come-dy.png";
import boss_woman from "./components/app_content/navigation_pages_content/images/testyourtwi.jpg";
import welcome_textile from "./components/app_content/navigation_pages_content/images/welcome-textile.png";
import i_subject from "./components/app_content/navigation_pages_content/images/i-subject.png";
import you_subject from "./components/app_content/navigation_pages_content/images/you-subject.png";
import you_object from "./components/app_content/navigation_pages_content/images/you-object.png";
import he_she_subject from "./components/app_content/navigation_pages_content/images/he-she-subject.png";
import certified from "./components/app_content/navigation_pages_content/images/certified.png";
import meal_hands from "./components/app_content/navigation_pages_content/images/meals-and-hands.png";
import palm_nut from "./components/app_content/navigation_pages_content/images/palm-nut.png";
import we_subject from "./components/app_content/navigation_pages_content/images/we-subject-pronoun-2.png";
import name_image from "./components/app_content/navigation_pages_content/images/what-is-your-name-2.png";

import ReactPixel from "react-facebook-pixel";
if (process.env.REACT_APP_DEV_PIPELINE_STAGE == "production") {
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_CODE, options);
  ReactPixel.pageView();
}
const GroupItem = ({ info, ctx }) => {
  // console.log("info======================:", info);
  const quiz_info = ctx.state.twi_quiz_association_info[info];
  const url_route = quiz_info[0];
  const image = quiz_info[1];
  const name = quiz_info[2];
  const display = ctx.quiz_info(info);
  return ctx.props.returnQuiz(url_route, image, name, display);
};
// const TwiQuizAssociationInfo = ({ quiz_name }) => {
//   var twi_quiz_association_info = {
//     twi_basics_1_blended: ["/basics-1-blended-quiz", akan_man_image, "Basics 2"],
//     basics_1_vocab: ["basics-1-vocab-quiz", akan_man_image, "Basics 1"],
//     basics_2_vocab: ["basics-2-vocab-quiz", akan_man_image, "Basics 2"],
//     culture: ["culture_quiz", culture_image, "Drum & Dance"],
//     medical_nouns_1_vocab: [
//       "medicalnouns_vocab_quiz",
//       medicalnouns_image,
//       "Health Care 1",
//     ],
//     medical_nouns_1: ["medicalnouns_quiz", medicalnouns_image, "Health Care 2"],
//     professions: ["professions_quiz", profession, "Professions"],
//     professions_2: ["professions_2_quiz", farmer_image, "Professions 2"],
//     market_food_1_vocab: ["marketfood_vocab_quiz", market, "Market Food 1"],
//     market_food_1: ["marketfood_quiz", market, "At The Market"],
//     home_verbs_1: ["homeverbs_quiz", homeverbs_image, "Home Verbs"],
//     market_food_2: ["marketfood2_quiz", market, "Market Food 2"],
//     market_food_3_vocab: ["marketfood_3_vocab_quiz", market, "Market Food 3"],
//     at_the_bar_vocab: ["atthebar_vocab_quiz", bar, "At The Bar 1"],
//     at_the_bar: ["atthebar_quiz", bar, "At The Bar 2"],
//     greetings: ["welcome_greetings", greetings_image, "Grammar 1"],
//     cards_1_manhyia: [
//       "dialogue-pack-1-manhyia",
//       // greetings_image,
//       adinkra_image,
//       // "Culture 1 - Akwaaba!",
//       "Culture 1",
//     ],
//     greetings_vocab: [
//       "greetings_vocab_quiz",
//       // greetings_image,
//       welcome_textile,
//       "Vocab 1",
//     ],
//     test_your_twi: ["test_your_twi_quiz", testyourtwi_image, "Test Your Twi"], //edit comp stats
//     home_nouns_1: ["homenouns_quiz", homenouns_image, "Home Nouns"],
//     at_home_2: ["at_home_2_quiz", at_home_2_image, "At Home"],
//     family: ["family_quiz", family_image, "Family"],
//     body_parts: ["bodyparts_quiz", bodyparts, "Body Parts"],
//     body_parts_2: ["bodyparts_2_quiz", bodyparts_2, "Body Parts 2"],
//     body_parts_3_tension: [
//       "bodyparts_3_tension_quiz",
//       bodyparts_2,
//       "Express Tension",
//     ],
//     colors: ["colors_quiz", colors_image, "Colors"], //edit comp stats

//     colors_2: ["colors_2_quiz", colors_image, "Colors 2"], //edit comp stats
//     romance: ["romance_quiz", romance_image, "Romance"],
//     romance_2: ["romance_2_quiz", romance_2_image, "Romance 2"],
//     animals: ["animals_quiz", animals_image, "Animals"],
//     doing_chores: ["chores_quiz", chores_image, "Doing Chores"],

//     at_school_1: ["at_school_1_quiz", at_school_2_image, "Education 1"],
//     at_school_2: ["at_school_2_quiz", student_image, "Education 3"],
//     at_school_3: ["at_school_3_quiz", teaching_image, "Education 2"],
//     at_school_4: ["at_school_4_quiz", teaching_image, "Education 4"],
//   };
//   return twi_quiz_association_info;
// };
const DiamondDisplay = (group_items, ctx) => {
  var top = "";
  var left = "";
  var right = "";
  var down = "";
  if (group_items.length > 0) {
    top = <GroupItem info={group_items[0]} ctx={ctx} />;
  }
  if (group_items.length > 1) {
    left = <GroupItem info={group_items[1]} ctx={ctx} />;
  }
  if (group_items.length > 2) {
    right = <GroupItem info={group_items[2]} ctx={ctx} />;
  }
  if (group_items.length > 3) {
    down = <GroupItem info={group_items[3]} ctx={ctx} />;
  }
  return (
    <label id="courserow">
      <label>{top}</label>
      <label>
        {left}
        {right}
      </label>
      <label>{down}</label>
    </label>
  );
};
const ListOfGroupItems = ({ group_items, ctx }) => {
  return <ul>{DiamondDisplay(group_items, ctx)}</ul>;
};
const ListOfGroups = ({ groups, ctx }) => {
  return (
    <ul>
      {groups.map((single_group) => (
        <ListOfGroupItems group_items={single_group} ctx={ctx} />
      ))}
    </ul>
  );
};

class TwiSkillTree extends Component {
  constructor(props) {
    super(props);
    this.returnSwahiliSkillTree = this.returnSkillTree.bind(this);
    this.getCompletionStats = this.getCompletionStats.bind(this);
    this.state = {
      // language_name : 'twi' // this.props.language_name
      // skill tree state info
      twi_lesson_display_groups: [
        // 1
        ["greetings_fixed_vocab"],
        ["greetings", "cards_1_manhyia"],
        ["basics_1_vocab"],
        ["ghana_games_major_cities"],
        ["greetings_vocab", "twi_basics_1_blended"],
        ["subject_pronouns_1_vocab"],
        ["subject_pronouns_1_blended"],
        ["object_pronouns_1_vocab"],
        ["object_pronouns_1_blended", "singular_pronouns_blended"],
        ["market_food_1_vocab", "medical_nouns_1_vocab", "medical_nouns_1"],
        ["at_the_bar_vocab", "market_food_1"],
        ["professions"],
        ["at_the_bar", "market_food_2"],
        ["romance", "culture", "professions_2"],
        ["animals", "body_parts"],
        ["family"],
        ["body_parts_2", "doing_chores"],
        ["home_nouns_1", "at_school_1", "at_home_2"],
        ["romance_2", "at_school_3"],
        ["colors"],
        ["colors_2", "at_school_2"],
        ["home_verbs_1", "market_food_3_vocab", "at_school_4"],
        [
          "body_parts_3_tension",
          //  "twi_basics_1_blended"
        ],
        ["romance_3_cute_idioms"],
        ["body_parts_4_wit", "twi_basics_1_blended_2"],
        ["body_parts_5_heated_emotion"],
        ["haggling", "twi_basics_1_blended_3", "haggling_blended"],
        ["women_in_ghana"],
        ["tenses_1"],
        ["tenses_2", "tenses_3"],
        ["tenses_4"],
        ["culture_food_ghana","haggling_intermediate"],
        ["twi_meals_1"],
        // next
        ["subject_pronouns_2_vocab"],
        ["twi_meals_2","twi_meals_3"],
        ["basics_vocab_2"],
        ["culture_dance","haggling_vocab_2"],
        ["names"],
        ["twi_intro_2"],
        ["twi_basics_1_blended_b","twi_meals_4"],
        ["home_verbs_2"],
        ["twi_responses_1","family_2"],
        ["at_the_market_3","days_of_week","twi_responses_2"],
        // // next
        // ["greetings"],
        // ["market_food_2"],
        // ["at_the_bar", "market_food_1"],
        // ["romance"],
        // ["animals"],
        // ["medical_nouns_1", "home_verbs_1"],
        // ["market_food_2"],
        // ["culture", "home_nouns_1"],
        // ["family", "body_parts", "colors"],
        // ["romance"],
        // ["doing_chores", "animals"],
        // ["romance_2", "at_home_2", "colors"],
        // // 3
        // ["greetings"],
        // ["at_the_bar", "market_food_1"],
        // ["medical_nouns_1", "professions", "home_verbs_1"],
        // ["market_food_2"],
        // ["culture", "home_nouns_1"],
        // ["family", "body_parts", "colors"],
        // ["romance"],
        // ["doing_chores", "animals"],
        // ["romance_2", "at_home_2", "colors"],
        // // 4
        // ["greetings"],
        // ["at_the_bar", "market_food_1"],
        // ["medical_nouns_1", "professions", "home_verbs_1"],
        // ["market_food_2"],
        // ["culture", "home_nouns_1"],
        // ["family", "body_parts", "colors"],
        // ["romance"],
        // ["doing_chores", "animals"],
        // ["romance_2", "at_home_2", "colors"],
        // // 5
        // ["greetings"],
        // ["at_the_bar", "market_food_1"],
        // ["medical_nouns_1", "professions", "home_verbs_1"],
        // ["market_food_2"],
        // ["culture", "home_nouns_1"],
        // ["family", "body_parts", "colors"],
        // ["romance"],
        // ["doing_chores", "animals"],
        // ["romance_2", "at_home_2", "colors"],
        // // 6
        // ["greetings"],
        // ["at_the_bar", "market_food_1"],
        // ["medical_nouns_1", "professions", "home_verbs_1"],
        // ["market_food_2"],
        // ["culture", "home_nouns_1"],
        // ["family", "body_parts", "colors"],
        // ["romance"],
        // ["doing_chores", "animals"],
        // ["romance_2", "at_home_2", "colors"],
      ],
      twi_quiz_association_info: {
        family_2: ["family_2_quiz", family_image, "Family 2"],
        twi_responses_2: ["/twi_responses_2_quiz", greetings_image, "Responses 2"],
        twi_responses_1: ["/twi_responses_1_quiz", welcome_textile, "Responses 1"],
        twi_intro_2: ["/twi_intro_2_quiz", welcome_textile, "Intro Details"],
        "subject_pronouns_2_vocab": [
          "subject-pronouns-2-vocab",
          we_subject,
          "We-You-They",
        ],
        names: ["days_of_week_quiz", name_image, "Names"],
        days_of_week: ["days_of_week_quiz", today_image, "Days"],
        twi_meals_4: ["twi_meals_4_quiz", meal_hands, "Delicacies 2"],
        twi_meals_3: ["twi_meals_3_quiz", palm_nut, "Delicacies 1"],
        twi_meals_2: ["twi_meals_2_quiz", meal_hands, "Order food 2"],
        twi_meals_1: ["twi_meals_1_quiz", meal_hands, "Order food"],
        haggling_intermediate: [
          "twi_haggling_3_quiz",
          cedi_image,
          "Haggling 3",
        ],
        tenses_2: ["twi_tenses_2_quiz", yesterday_image, "Tenses 2"],
        tenses_3: ["twi_tenses_3_quiz", tomorrow_image, "Tenses 3"],
        tenses_4: ["twi_tenses_4_quiz", today_image, "Tenses 4"],
        culture_dance: ["culture_dance_quiz", culture_image, "Culture: Dance"],
        basics_vocab_2: [
          "/basics-1-vocab-2-quiz",
          come_image,
          "Basics Vocab 2",
        ],
        haggling_vocab_2: [
          "twi_haggling_vocab_2_quiz",
          haggling_image,
          "Haggling 4",
        ],
        women_in_ghana: ["women_culture_quiz", boss_woman, "Women in Ghana"],
        tenses_1: ["twi_tenses_quiz", today_image, "Tenses 1"],
        twi_basics_1_blended_b: [
          "/basics-1-blended-quiz_b",
          today_image,
          "Casual Talk",
        ],
        at_the_market_3: [
          "twi_at_the_market_3_quiz",
          homeverbs_image,
          "Intentions",
        ],
        haggling_blended: [
          "twi_haggling_blended_quiz",
          this_image,
          "Haggling 2",
        ],
        // at_the_market_3: ["twi_at_the_market_3_quiz", market, "Basics 3"],
        // haggling_blended: ["twi_at_the_market_3_quiz", market, "Haggling 3"],
        greetings_fixed_vocab: [
          "/greetings_fixed_vocab",
          welcome_textile,
          "Welcome/Intro",
          // Ohemaa, ɛte sɛn? | Ohene, bɔkɔɔ.
          // #c20245
          // Ohemaa, ɛte sɛn?  [Ohene, bɔkɔɔ]
        ],
        singular_pronouns_blended: [
          "singular_pronouns_blended_quiz",
          he_she_subject,
          "Relations",
        ],
        object_pronouns_1_vocab: [
          "object_pronouns_1_vocab_quiz",
          you_object,
          "Me-You-It",
        ],
        object_pronouns_1_blended: [
          "object_pronouns_1_blended_quiz",
          you_subject,
          "Pronouns 4",
        ],
        subject_pronouns_1_vocab: [
          "subject_pronouns_1_vocab_quiz",
          i_subject,
          "I-You-It",
        ],
        subject_pronouns_1_blended: [
          "subject_pronouns_1_blended_quiz",
          you_subject,
          "Pronouns 2",
        ],
        home_verbs_2: ["homeverbs_quiz", homeverbs_image, "Home Verbs 2"],
        body_parts_5_heated_emotion: [
          "bodyparts_5_heated_emotion_quiz",
          fire_image,
          "Heated Emotion",
        ],
        haggling: ["twi_haggling_quiz", haggling_image, "Haggling Vocab"],
        body_parts_4_wit: [
          "bodyparts_4_blended_quiz",
          bodyparts_2,
          "Express Wit",
        ],
        romance_3_cute_idioms: [
          "romance_3_cute_idioms_quiz",
          romance_2_image,
          "Cute Idioms",
        ],
        basics_1_vocab: ["basics-1-vocab-quiz", akan_man_image, "Basics 1"],
        twi_basics_1_blended: [
          "/welcome_basic_grammar",
          today_image,
          "Basic Grammar",
        ],
        twi_basics_1_blended_2: [
          "/basics-1-blended-2-quiz",
          certified,
          "To understand",
        ],
        twi_basics_1_blended_3: [
          "/basics-1-blended-3-quiz",
          certified,
          "Agreement",
        ],
        culture: ["culture_quiz", culture_image, "Drum & Dance"],
        medical_nouns_1_vocab: [
          "medicalnouns_vocab_quiz",
          medicalnouns_image,
          "Health Care 1",
        ],
        medical_nouns_1: [
          "medicalnouns_quiz",
          medicalnouns_image,
          "Health Care 2",
        ],
        professions: ["professions_quiz", profession, "Professions"],
        professions_2: ["professions_2_quiz", farmer_image, "Professions 2"],
        market_food_1_vocab: ["marketfood_vocab_quiz", market, "Market Food 1"],
        market_food_1: ["marketfood_quiz", market, "At The Market"],
        home_verbs_1: ["homeverbs_quiz", homeverbs_image, "Home Verbs"],
        market_food_2: ["marketfood2_quiz", market, "Market Food 2"],
        market_food_3_vocab: [
          "marketfood_3_vocab_quiz",
          market,
          "Market Food 3",
        ],
        at_the_bar_vocab: ["atthebar_vocab_quiz", bar, "At The Bar 1"],
        at_the_bar: ["atthebar_quiz", bar, "At The Bar 2"],
        greetings: ["welcome_greetings", greetings_image, "Greetings"],
        cards_1_manhyia: [
          "dialogue-pack-1-manhyia",
          // greetings_image,
          adinkra_image,
          // "Culture 1 - Akwaaba!",
          "Culture 1",
        ],
        greetings_vocab: [
          "greetings_vocab_quiz",
          greetings_image,
          // welcome_textile,
          "Greetings Vocab",
        ],
        test_your_twi: [
          "test_your_twi_quiz",
          testyourtwi_image,
          "Test Your Twi",
        ], //edit comp stats
        home_nouns_1: ["homenouns_quiz", homenouns_image, "Home Nouns"],
        at_home_2: ["at_home_2_quiz", at_home_2_image, "At Home"],
        family: ["family_quiz", family_image, "Family"],
        body_parts: ["bodyparts_quiz", bodyparts, "Body Parts"],
        body_parts_2: ["bodyparts_2_quiz", pain_image, "Body Pain"],
        body_parts_3_tension: [
          "bodyparts_3_tension_quiz",
          bodyparts_2,
          "Express Tension",
        ],
        colors: ["colors_quiz", colors_image, "Colors"], //edit comp stats

        colors_2: ["colors_2_quiz", colors_image, "Colors 2"], //edit comp stats
        romance: ["romance_quiz", romance_image, "Romance"],
        romance_2: ["romance_2_quiz", romance_2_image, "Romance 2"],
        animals: ["animals_quiz", animals_image, "Animals"],
        doing_chores: ["chores_quiz", chores_image, "Doing Chores"],

        at_school_1: ["at_school_1_quiz", at_school_2_image, "Education 1"],
        at_school_2: ["at_school_2_quiz", student_image, "Education 3"],
        at_school_3: ["at_school_3_quiz", teaching_image, "Education 2"],
        at_school_4: ["at_school_4_quiz", teaching_image, "Education 4"],
        culture_food_ghana: [
          "culture_food_ghana_quiz",
          rubiks_image,
          "Puzzle: Local Dishes",
        ],
        ghana_games_major_cities: [
          "ghana_games_major_cities_quiz",
          rubiks_cities_image,
          "Puzzle: Major Cities",
        ],
      },
      twi_non_limited_quizzes: new Set([
        "greetings",
        "market_food_1_vocab",
        "professions",
        "romance",
        "family",
        "home_nouns_1",
        "colors",
        "home_verbs_1",
        // "cards_1_manhyia",
        "greetings_vocab",
        "object_pronouns_1_vocab",
        "haggling",
        // "basics_1_vocab",
        "greetings_fixed_vocab",
        // "culture_food_ghana",
      ]),
      twi_playable_quizzes_set: new Set([
        // culture
        "cards_1_manhyia",
        // language
        "home_nouns_1",
        "medical_nouns_1",
        "medical_nouns_1_vocab",
        "home_verbs_1",
        "professions",
        "professions_2",
        "family",
        "body_parts",
        "body_parts_2",
        "body_parts_3_tension",
        "colors",
        "romance",
        "animals",
        "culture",
        "market_food_1",
        "market_food_1_vocab",
        "market_food_2",
        "market_food_3_vocab",
        "at_the_bar",
        "at_the_bar_vocab",
        "greetings",
        "greetings_vocab",
        "test_your_twi",
        "at_home_2",
        "romance_2",
        "doing_chores",
        "colors_2",
        "at_school_2",
        "at_school_1",
        "at_school_3",
        "at_school_4",
        "basics_1_vocab",
        "twi_basics_1_blended",
        "subject_pronouns_1_vocab",
        "subject_pronouns_1_blended",
        "object_pronouns_1_vocab",
        "object_pronouns_1_blended",
        "romance_3_cute_idioms",
        "singular_pronouns_blended",
        "greetings_fixed_vocab",
        "body_parts_4_wit",
        "body_parts_5_heated_emotion",
        // "twi_basics_1_blended_b",
        "women_in_ghana",
        "tenses_1",
        "tenses_2",
        "tenses_3",
        "tenses_4",
        "twi_basics_1_blended_2",
        "twi_basics_1_blended_3",
        "haggling",
        "haggling_blended",
        "culture_food_ghana",
        "ghana_games_major_cities",
        "haggling_intermediate",
        "twi_meals_1",
        "subject_pronouns_2_vocab",
        "twi_meals_2"
      ]),
      lesson_stats: {},
    };
  }

  componentDidMount() {
    this.getCompletionStats(this.state.twi_playable_quizzes_set);
  }

  getCompletionStats(quiz_names) {
    this.props
      .getCompletionStats(quiz_names)
      .then((newItems) => this.setState({ lesson_stats: newItems }));
  }

  quiz_info(quiz_name) {
    const in_tree = this.state.twi_playable_quizzes_set.has(quiz_name);
    const stats = this.state.lesson_stats[quiz_name];
    const limited = !this.state.twi_non_limited_quizzes.has(quiz_name);
    var quiz_info_arr = [quiz_name, in_tree, stats, limited];
    return quiz_info_arr;
  }

  returnSkillTree() {
    return (
      <div className="courselineditems">
        <ListOfGroups
          groups={this.state.twi_lesson_display_groups}
          ctx={this}
        />
      </div>
    );
  }

  render() {
    return <div>{this.returnSkillTree()}</div>;
  }
}
export default TwiSkillTree;
